import translations from '../services/translations'
import { ROLE_FORM } from '../../../constants/roles'
import { GFPP } from './manifest-commons'
import { AppStateObject } from '../app-state/app-state-builder'
import * as _ from 'lodash'
import { FormPlugin } from '../../../constants/plugins'

export const createHiddenMessageManifest = ({ plugins }: AppStateObject = {}) => ({
  relatedToRole: ROLE_FORM,
  displayName: translations.t(
    `manifest.${_.includes(plugins, FormPlugin.REGISTRATION_FORM) ? 'registrationFormMessage' : 'hiddenMessage'}.displayName`
  ),
  behavior: { duplicatable: false },
  confirmOnDelete: {
    title: translations.t(
      `manifest.${_.includes(plugins, FormPlugin.REGISTRATION_FORM) ? 'registrationFormMessage' : 'hiddenMessage'}.confirmOnDelete.title`
    ),
    contentText: translations.t(
      `manifest.${
        _.includes(plugins, FormPlugin.REGISTRATION_FORM) ? 'registrationFormMessage' : 'hiddenMessage'
      }.confirmOnDelete.contentText`
    ),
  },
  gfpp: {
    desktop: {
      iconButtons: {
        connect: GFPP.REMOVE,
      },
    },
  },
})
