import translations from '../services/translations'
import { ROLE_FORM } from '../../../constants/roles'
import { GFPP, GFPP_ACTIONS } from './manifest-commons'

export const createTitleManifest = () => ({
  relatedToRole: ROLE_FORM,
  displayName: translations.t('manifest.title.displayName'),
  behavior: { duplicatable: false },
  gfpp: {
    desktop: {
      iconButtons: {
        [GFPP_ACTIONS.SETTINGS]: GFPP.KEEP_DEFAULT,
        [GFPP_ACTIONS.ANIMATION]: GFPP.KEEP_DEFAULT,
        [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
      },
    },
    mobile: {
      /* use default configurations */
    },
  },
})
