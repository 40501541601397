export const MESSAGE_EXTRA_HEIGHT = 15
export const SUBMIT = {
  FORM_EXTRA_HEIGHT: 40,
  BUTTON_HEIGHT: 50,
  MESSAGE: 40,
}
export const HIDDEN_MESSAGE = {
  FORM_EXTRA_HEIGHT: 21,
  HIDDEN_MESSAGE_HEIGHT: 20,
  REGISTRATION_ERROR_MESSAGE_HEIGHT: 18,
}
