export const FORM_PADDING = 60
export const ADI_FORM_PADDING = 20
export const PADDING_FROM_TITLE = 15
export const FIELD_MARGIN = 30
export const TOP_PADDING = 35
export const ROLE_PADDING = {
  SUBMIT: 30,
  MESSAGE: 90,
}
export const LAST_FIELD_PADDING = 150
export const ADI_LAST_FIELD_PADDING = 150

export type fieldsLayout = {
  lastFieldPadding: number
  heightPadding: number
  startX: number
  formPadding: number
  defaultWidth: Function
}

export const getFieldsLayout = (isAdiLayout, showTitles) => {
  const fieldsLayout: fieldsLayout =
    {
      lastFieldPadding: isAdiLayout ? ADI_LAST_FIELD_PADDING : LAST_FIELD_PADDING,
      heightPadding: (isAdiLayout && !showTitles ? 10 : 32),
      startX: isAdiLayout ? 0 : FORM_PADDING,
      formPadding: isAdiLayout ? ADI_FORM_PADDING : FORM_PADDING,
      defaultWidth: (width, columns) => {
        return isAdiLayout ? (width / columns) : (width - FORM_PADDING * 2 - (columns - 1) * FIELD_MARGIN) / columns
      },
    }
  return fieldsLayout
}