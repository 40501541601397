export enum COMPONENT_TYPES {
  TEXT_INPUT = 'wysiwyg.viewer.components.inputs.TextInput',
  RADIO_GROUP = 'wysiwyg.viewer.components.inputs.RadioGroup',
  DATE_PICKER = 'wysiwyg.viewer.components.inputs.DatePicker',
  COMBOBOX = 'wysiwyg.viewer.components.inputs.ComboBoxInput',
  TEXT_AREA_INPUT = 'wysiwyg.viewer.components.inputs.TextAreaInput',
  FILE_UPLOADER = 'wysiwyg.viewer.components.inputs.FileUploader',
  CHECKBOX_GROUP = 'wysiwyg.viewer.components.inputs.CheckboxGroup',
  SINGLE_CHECKBOX = 'wysiwyg.viewer.components.inputs.Checkbox',
  RICH_TEXT = 'wysiwyg.viewer.components.WRichText',
  RATING = 'wixui.RatingsInput',
  FORM_CONTAINER = 'wysiwyg.viewer.components.FormContainer',
}
