export type MediaManagerUploadedObjectResponse = {
  uri: string
  title: string
}

export type MediaManagerUploadObjectRequest = {
  type: LinkPanelTypes
  docId?: string
  name?: string
  status: UploadStatuses
}

export enum LinkPanelTypes {
  NONE = 'none',
  PAGE = 'PageLink',
  LIGHTBOX = 'PageLink',
  EXTERNAL_LINK = 'ExternalLink',
  DOCUMENT_LINK = 'DocumentLink',
}

export const LinkPanelTypesToActionTypes = {
  [LinkPanelTypes.PAGE]: 'LINK',
  [LinkPanelTypes.EXTERNAL_LINK]: 'EXTERNAL_LINK',
  [LinkPanelTypes.DOCUMENT_LINK]: 'DOWNLOAD_DOCUMENT',
}


export enum UploadStatuses {
  UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
  IN_PROCESS = 'IN_PROCESS',
  NONE = 'NONE',
}

export const DEFAULT_UPLOAD_OBJECT: MediaManagerUploadObjectRequest = {
  type: LinkPanelTypes.DOCUMENT_LINK,
  status: UploadStatuses.NONE,
  name: '',
  docId: '',
}

export const DEFAULT_LINK_OBJECT = { type: LinkPanelTypes.PAGE, target: '_self' }
export const DEFAULT_EXTERNAL_LINK_OBJECT = { type: LinkPanelTypes.EXTERNAL_LINK, target: '_self' }

export const VISIBLE_LINK_PANEL_SECTIONS = {
  PageLink: true,
  AnchorLink: false,
  NoLink: true,
  PhoneLink: false,
  ExternalLink: true,
  EmailLink: false,
  DocumentLink: false,
  LoginToWixLink: false,
  EdgeAnchorLinks: false,
  PopupLink: true,
}

export const VISIBLE_LINK_PANEL_SECTIONS_ADI = {
  PageLink: true,
  AnchorLink: false,
  NoLink: true,
  PhoneLink: false,
  ExternalLink: true,
  EmailLink: false,
  DocumentLink: false,
  LoginToWixLink: false,
  EdgeAnchorLinks: false,
  PopupLink: false,
}
