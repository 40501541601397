import { ROLE_FIELD_REGISTRATION_FORM_TERMS_OF_USE } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import { ConnectedElementPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { iconNames } from '../../icons/types'

const makeTermsOfUse = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: false,
    componentType: COMPONENT_TYPES.RICH_TEXT,
    extraData: {
      role: ROLE_FIELD_REGISTRATION_FORM_TERMS_OF_USE,
      data: {
        text: `<p class="font_8"><span>${t(
          `fieldTypes.${ConnectedElementPreset.REGISTRATION_FORM_TERMS_OF_USE}`
        )}</span></p>`,
      },
    },
  }
}

export const termsOfUseData: FieldData = {
  make: makeTermsOfUse,
  customFields: [],
  icon: iconNames.link
}
