import { createSuffixedName } from '../../../utils/utils'
import translations from '../services/translations'
import { EVENTS } from '../../../constants/bi'
import * as _ from 'lodash'
import {
  FieldCollectionType,
  Permissions,
  PermissionType,
  SUBMISSION_DISPLAY_FIELD,
} from '../../../constants/wixcode'
import { withBi, isInputField } from '../utils'
import { ComponentRef } from '../api-types'

const WIXCODE_APP_DEF_ID = '675bbcef-18d8-41f5-800e-131ec9e08762'

export default class CollectionsApi {
  private collectionsApi: any
  private boundEditorSDK: any
  private collectionsPermissionsApi: any
  private biLogger: any

  constructor(
    boundEditorSDK: any,
    collectionsApi: any,
    collectionsPermissionsApi: any,
    { biLogger }
  ) {
    this.collectionsApi = collectionsApi
    this.boundEditorSDK = boundEditorSDK
    this.collectionsPermissionsApi = collectionsPermissionsApi
    this.biLogger = biLogger
  }
  private _installWixCode() {
    if (this._isWixCodeInstalled()) {
      return Promise.resolve()
    }

    return this.boundEditorSDK.document.application.install({
      appDefinitionId: WIXCODE_APP_DEF_ID,
    })
  }

  private _isWixCodeInstalled() {
    try {
      const wixCodeApi = this.boundEditorSDK.document.application.getPublicAPI({
        appDefinitionId: 'wix-code',
      })
      return !!wixCodeApi
    } catch (ex) {
      return false
    }
  }

  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.CREATE_SUBMISSIONS_TABLE,
    endEvid: EVENTS.PANELS.settingsPanel.SUBMISSIONS_TABLE_CREATED_SUCCESSFULLY,
  })
  public async createCollection({ preset }, _biData = {}) {
    await this._installWixCode()
    const collections = await this.collectionsApi.list()
    const collectionId = createSuffixedName(_.map(collections, 'id'), _.camelCase(preset), '')
    await this.collectionsApi.create({ collectionId })
    const permissions: Permissions = {
      read: PermissionType.ADMIN,
      insert: PermissionType.ANYONE,
      update: PermissionType.ADMIN,
      remove: PermissionType.ADMIN,
    }
    await this.collectionsPermissionsApi.setCollectionPermissions({ collectionId, permissions })
    await this.collectionsApi.addField({
      collectionId,
      fieldKey: SUBMISSION_DISPLAY_FIELD,
      displayName: translations.t(`addForm.submissions.${SUBMISSION_DISPLAY_FIELD}`),
      type: FieldCollectionType.DATETIME,
    })
    await Promise.all([
      this.collectionsApi.setDisplayField({
        collectionId,
        displayField: SUBMISSION_DISPLAY_FIELD,
      }),
      this.collectionsApi.removeField({ collectionId, fieldKey: 'title' }),
    ])

    return collectionId
  }

  public async addFieldsToCollection(
    collectionId,
    fieldsData,
    fieldKeyCallback: (component: ComponentRef, fieldKey: string) => void
  ) {
    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }

    const fieldKeys = []
    const fieldsCollectionData = _.filter(fieldsData, ({ role }) => isInputField(role)).map(
      ({ crmLabel, collectionFieldKey, collectionFieldType, componentRef }) => {
        let fieldKey

        if (collectionFieldKey) {
          fieldKey = collectionFieldKey
        } else {
          fieldKey = createSuffixedName(fieldKeys, _.camelCase(crmLabel), '')
          fieldKeyCallback(componentRef, fieldKey)
        }

        fieldKeys.push(fieldKey)

        return {
          fieldKey,
          displayName: crmLabel,
          type: collectionFieldType || FieldCollectionType.TEXT,
        }
      }
    )
    return this.collectionsApi.addFields({
      collectionId,
      fields: fieldsCollectionData,
    })
  }

  public async isCollectionExists(collectionId) {
    if (!this._isWixCodeInstalled()) {
      return false
    }

    return _.some(
      await this.collectionsApi.list().catch(() => {
        {
        }
      }),
      { id: collectionId }
    )
  }

  public async getCollectionMapById() {
    return _.keyBy(
      await this.collectionsApi.list().catch(() => {
        {
        }
      }),
      'id'
    )
  }

  public async addFieldToCollection(collectionId, fieldConnectionConfig) {
    const { collectionFieldKey, crmLabel, collectionFieldType } = fieldConnectionConfig
    if (!collectionId || !collectionFieldType) {
      return
    }

    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }

    await this.collectionsApi.addField({
      collectionId,
      fieldKey: collectionFieldKey,
      displayName: crmLabel,
      type: collectionFieldType,
    })
  }

  public async updateField(collectionId, fieldKey, displayName) {
    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }

    return this.collectionsApi.updateField({
      collectionId,
      fieldKey,
      displayName,
    })
  }
}
