import * as _ from 'lodash'
import { FieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { generalTextData } from '../../general-fields/definitions/general-text'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

const makeCompany = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalTextData.make(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.COMPANY,
        crmLabel: t(`fieldTypes.${FieldPreset.COMPANY}`),
      },
      props: { placeholder: t(`fieldTypes.${FieldPreset.COMPANY}`) },
      data: {
        maxLength: 100,
        placeholder: t(`fieldTypes.${FieldPreset.COMPANY}`),
      },
    },
  })
}

export const companyData: FieldData = {
  make: makeCompany,
  customFields: [],
  icon: iconNames.company
}
