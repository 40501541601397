import * as _ from 'lodash'
import * as formsApi from './../core/forms-api.json'
import { getApi } from '../editor-ready/editor-ready'

export const fetcher = () => {
  let resolveCall
  const fetcherPromise: Promise<any> = new Promise(resolve => {
    resolveCall = resolve
  })
  return {
    resolveData: resolveCall,
    getData: fetcherPromise,
  }
}

export const generateExportedApi = () => {
  const createApiFunction = path => async (...payload) => {
    const api = await getApi()
    const apiFunction = path.reduce((acc, p) => {
      if (_.isFunction(acc[p])) {
        return acc[p].bind(acc)
      }
      return acc[p]
    }, api)
    return apiFunction(...payload)
  }
  const apiPaths = getAllPaths(formsApi)
  return apiPaths.reduce((acc, path) => {
    const pathKey = path.join('_')
    acc[pathKey] = createApiFunction(path)
    return acc
  }, {})
}

const getAllPaths = api => {
  let paths = []
  const walk = (obj, path) => {
    path = path || []
    for (let n in obj) {
      if (!obj.hasOwnProperty(n) || n == 'default') continue
      if (typeof obj[n] === 'object') {
        walk(obj[n], path.concat(n))
      } else {
        paths.push(path.concat(n))
      }
    }
  }
  walk(api, [])
  return paths
}
