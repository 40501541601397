import * as _ from 'lodash'
import { isWixEmployeeEmail } from '../../utils/utils'
import { ROLE_FORM, ROLE_MESSAGE, ROLE_SUBMIT_BUTTON } from '../../constants/roles'
import translations from './services/translations'
import {
  ComponentConnection,
  ComponentRef,
  CriticalComponentTypes,
  FormFieldDefinition,
  Plugin,
  FormSnapshot,
} from './api-types'
import { SuccessActionTypes } from '../../constants/success-settings'
import RemoteApi from '../../panels/commons/remote-api'
import { EVENTS } from '../../constants/bi'
import {
  generateRuntimeCoreApi,
  getValidCollectionId,
  undoable,
  withBi,
  compareObjectsAndUpdateIfNeeded,
  componentsDiff,
} from './utils'
import LayoutApi from './layout-panel/api'
import StyleApi from './style-panel/api'
import AddFormApi from './add-form/api'
import CollectionsApi from './collections/api'
import SettingsApi from './settings-panel/api'
import FirstTimeApi from './first-time-panel/api'
import ManagePanelsApi from './manage-panels/api'
import FieldsApi from './fields/api'
import PremiumApi from './premium/api'
import AppState from './app-state/app-state'
import { FieldPreset } from '../../constants/field-types'
import { getFieldProperties, FieldProperties } from './preset/fields/field-types-data'
import { FormPlugin } from '../../constants/plugins'
import { CRM_TAGS, CRM_TYPES } from '../../constants/crm-types-tags'

export default class CoreApi {
  private experiments: any
  private ravenInstance: any
  private remoteApi: RemoteApi
  private biLogger: any
  private origin: any

  public layout: LayoutApi
  public style: StyleApi
  public addForm: AddFormApi
  public collectionsApi: CollectionsApi
  public settings: SettingsApi
  public firstTimePanel: FirstTimeApi
  public managePanels: ManagePanelsApi
  public fields: FieldsApi
  public premium: PremiumApi
  public appState: AppState

  constructor(
    private boundEditorSDK,
    private editorSDK,
    {
      apis: { collectionsApi, collectionsPermissionsApi, remoteApi },
      experiments,
      ravenInstance,
      biLogger,
      origin
    }
  ) {
    const helpers = { experiments, biLogger }
    this.collectionsApi = new CollectionsApi(
      boundEditorSDK,
      collectionsApi,
      collectionsPermissionsApi,
      helpers
    )
    this.remoteApi = remoteApi
    this.boundEditorSDK = boundEditorSDK
    this.editorSDK = editorSDK
    this.experiments = experiments
    this.ravenInstance = ravenInstance
    this.biLogger = biLogger
    this.origin = origin

    this.layout = new LayoutApi(boundEditorSDK, this, helpers)
    this.style = new StyleApi(boundEditorSDK, this, helpers)
    this.addForm = new AddFormApi(boundEditorSDK, this, this.collectionsApi, remoteApi, helpers)
    this.settings = new SettingsApi(boundEditorSDK, editorSDK, this, remoteApi, helpers)
    this.managePanels = new ManagePanelsApi(boundEditorSDK, editorSDK, this, helpers)
    this.fields = new FieldsApi(boundEditorSDK, this, remoteApi, helpers)
    this.premium = new PremiumApi(boundEditorSDK, this, remoteApi, helpers)
    this.appState = new AppState(boundEditorSDK, this, remoteApi, helpers)
    this.firstTimePanel = new FirstTimeApi(boundEditorSDK)
  }

  public async replaySnapshot(formRef: ComponentRef, prevSnapshot: FormSnapshot) {
    const currentSnapshot = await this.takeSnapshot(formRef)
    const updateExistingComponents = () => {
      prevSnapshot.components.forEach(async component => {
        const currComponent = currentSnapshot.components.find(
          comp => comp.componentRef.id === component.componentRef.id
        )
        if (!currComponent) return
        const componentRef = component.componentRef
        await Promise.all([
          updateConfig(componentRef, currComponent.config, component.config),
          updateLayout(componentRef, currComponent.layout, component.layout),
          updateData(componentRef, currComponent.data, component.data),
          updateProps(componentRef, currComponent.props, component.props),
        ])
      })
    }
    const deleteComponents = componentsToDelete => {
      return componentsToDelete.map(componentRef =>
        this.boundEditorSDK.components.remove({ componentRef })
      )
    }
    const addComponents = componentsToAdd => {
      return componentsToAdd.map(component => {
        const data = _.omit(component, ['role', 'config'])
        return this.fields.restoreField(formRef, {
          data,
          role: component.role,
          config: component.config,
        })
      })
    }
    const updateConfig = (componentRef, currConfig, prevConfig) =>
      compareObjectsAndUpdateIfNeeded(componentRef, currConfig, prevConfig, (componentRef, valueToUpdate) =>
        this.setComponentConnection(componentRef, valueToUpdate)
      )
    const updateLayout = (componentRef, currLayout, prevLayout) =>
      compareObjectsAndUpdateIfNeeded(componentRef, currLayout, prevLayout, (componentRef, valueToUpdate) =>
        this.layout.updateFieldLayout(componentRef, valueToUpdate)
      )
    const updateData = (componentRef, currData, prevData) =>
      compareObjectsAndUpdateIfNeeded(componentRef, currData, prevData, (componentRef, valueToUpdate) =>
        this.boundEditorSDK.components.data.update({
          componentRef,
          data: valueToUpdate,
        })
      )
    const updateProps = (componentRef, currProps, prevProps) =>
      compareObjectsAndUpdateIfNeeded(componentRef, currProps, prevProps, (componentRef, valueToUpdate) =>
        this.boundEditorSDK.components.properties.update({
          componentRef,
          props: valueToUpdate,
        })
      )
    await updateConfig(formRef, currentSnapshot.config, prevSnapshot.config)
    const { componentsToAdd, componentsToDelete } = componentsDiff(
      currentSnapshot.components,
      prevSnapshot.components
    )

    await updateExistingComponents()
    return Promise.all([...deleteComponents(componentsToDelete), ...addComponents(componentsToAdd)])
  }

  public initiator() {
    return _.get(this.origin, 'initiator')
  }

  public updateOrigin(origin) {
    this.origin = origin
  }

  public async takeSnapshot(formRef: ComponentRef): Promise<FormSnapshot> {
    const { config } = await this.getComponentConnection(formRef)

    const componentsIds = await this.fields.getRawFields(formRef)
    const componentsData = await this.boundEditorSDK.components.get({
      componentRefs: componentsIds,
      properties: [
        'data',
        'props',
        'layout',
        'connections',
        'style',
        'componentType',
        'components',
        'skin',
      ],
    })
    const componentsDataAndConfig = componentsData
      .map(component => {
        const { role, config } = _.find(component.connections, comp => comp.isPrimary)
        if (role === ROLE_FORM) return

        return _.merge(_.omit(component, 'connections'), { role, config })
      })
      .filter(c => c)

    return {
      components: componentsDataAndConfig,
      config,
    }
  }

  public setBiLogger(biLogger) {
    this.biLogger = biLogger
  }

  public async connect(
    { connectionConfig, role },
    controllerRef: ComponentRef,
    connectToRef: ComponentRef
  ) {
    await this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig,
      isPrimary: true,
    })
    return { role, connectionConfig, connectToRef, controllerRef }
  }

  public async addComponentAndConnect(
    { data, connectionConfig, role },
    controllerRef: ComponentRef,
    containerRef: ComponentRef
  ) {
    const connectToRef = await this.boundEditorSDK.components.add({
      componentDefinition: data,
      pageRef: containerRef,
    })

    return this.connect({ connectionConfig, role }, controllerRef, connectToRef)
  }

  public async getFormId(componentRef) {
    const formCompRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    return formCompRef.id
  }

  public async findConnectedComponent(
    controllerRef: ComponentRef,
    childRole
  ): Promise<ComponentRef> {
    const childComps = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const obj = await Promise.all(
      childComps.map(async child => {
        const { role } = await this.getComponentConnection(child)
        return role === childRole ? child : null
      })
    )
    return <ComponentRef | null>_.find(obj, x => !!x)
  }

  private async _findConnectedComponentsByFieldType(
    controllerRef: ComponentRef,
    componentFieldType: FieldPreset
  ): Promise<ComponentRef[]> {
    const childComps = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })

    const obj = await Promise.all(
      childComps.map(async child => {
        const { config } = await this.getComponentConnection(child)
        const fieldType = _.get(config, 'fieldType')
        return fieldType === componentFieldType ? child : null
      })
    )

    return <ComponentRef[]>_.filter(obj, x => !!x)
  }

  public async findComponentByRole(componentRef: ComponentRef, childRole) {
    const { controllerRef } = await this.getComponentConnection(componentRef)
    return this.findConnectedComponent(controllerRef, childRole)
  }

  public async getFormContainerOfAppWidget(componentRef: ComponentRef) {
    return (await this.boundEditorSDK.components.getChildren({ componentRef }))[0]
  }

  public generateRuntimeApi() {
    return generateRuntimeCoreApi(
      this,
      {
        layout: this.layout,
        style: this.style,
        settings: this.settings,
        firstTimePanel: this.firstTimePanel,
        addForm: this.addForm,
        managePanels: this.managePanels,
        fields: this.fields,
        premium: this.premium,
        appState: this.appState,
      },
      this.ravenInstance
    )
  }

  public async getComponentConnection(componentRef: ComponentRef): Promise<ComponentConnection> {
    const connections = await this.boundEditorSDK.controllers.listConnections({
      componentRef,
    })
    return _.find(connections, ['isPrimary', true]) || {}
  }

  public async setComponentConnection(
    connectToRef: ComponentRef,
    connectionConfig,
    deepMerge = true
  ) {
    const { controllerRef, role, config } = await this.getComponentConnection(connectToRef)
    const mergedConfig = (deepMerge ? _.merge : _.assign)({}, config, connectionConfig)

    return this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig: mergedConfig,
      isPrimary: true,
    })
  }

  public async saveSite() {
    return this.boundEditorSDK.editor.save()
  }

  public async saveSiteIfUnsaved() {
    const isSiteSaved = await this.boundEditorSDK.info.isSiteSaved()
    return isSiteSaved || this.saveSite()
  }

  @undoable()
  public async createCollection(componentRef: ComponentRef, biData = {}): Promise<string> {
    const {
      config: { formName, preset },
    } = await this.getComponentConnection(componentRef)
    const collectionId = await this.collectionsApi.createCollection({ preset }, biData)
    await this.collectionsApi.addFieldsToCollection(
      collectionId,
      await this.fields.getFieldsSortByXY(componentRef),
      (fieldComponent, fieldKey) =>
        this.setComponentConnection(fieldComponent, { collectionFieldKey: fieldKey })
    )
    await this.setComponentConnection(componentRef, {
      collectionId: `${componentRef.id}_${collectionId}`,
    })
    await this.editDraft(componentRef, formName, collectionId)
    await this.saveSite()

    return collectionId
  }

  public async getOwnerEmailId() {
    const data = await this.remoteApi.getOwnerEmail().catch(() => null)
    return data ? (isWixEmployeeEmail(data.email) ? '' : data.emailId) : ''
  }

  public async editDraft(componentRef, formName, collectionId = null) {
    const connectedFields = await this.fields.getFieldsSortByXY(componentRef)
    const formData: any = {
      formId: componentRef.id,
      formName,
      fields: _.map(connectedFields, connectedField => ({
        fieldId: connectedField.componentRef.id,
        fieldName: connectedField.crmLabel,
      })),
    }

    if (collectionId) {
      formData.collectionId = collectionId
    }

    return this.remoteApi.editDraft(formData)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.manageFieldsPanel.DELETE_FIELD })
  public removeComponent(componentRef: ComponentRef, _biData = {}) {
    return this.removeComponentRef(componentRef)
  }

  public removeComponentRef(componentRef: ComponentRef) {
    return this.boundEditorSDK.components.remove({ componentRef })
  }

  public async getButtonLabel(componentRef: ComponentRef): Promise<string> {
    const { label } = await this.boundEditorSDK.components.data.get({ componentRef })
    return label
  }

  @undoable()
  public updateButtonLabel(componentRef: ComponentRef, buttonLabel) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { label: buttonLabel },
    })
  }

  public async isTemplate(): Promise<boolean> {
    return !(await this.boundEditorSDK.info.isSiteSaved())
  }

  public getMetaSiteId(): Promise<string> {
    return this.boundEditorSDK.info.getMetaSiteId()
  }

  public getAppDefinitionId(): Promise<string> {
    return this.boundEditorSDK.document.info.getAppDefinitionId()
  }

  public getEditorSessionId(): Promise<string> {
    return this.boundEditorSDK.info.getEditorSessionId()
  }

  public async isMobileMode(): Promise<boolean> {
    return (await this.boundEditorSDK.info.getEditorMode()) === 'mobile'
  }

  private async _isComponentExists(componentRef: ComponentRef) {
    try {
      const componentType = await this.boundEditorSDK.components.getType({ componentRef })
      return !!componentType
    } catch (ex) {
      return false
    }
  }

  private async _isSubscribeFieldExistsWithoutEmailField(
    controllerRef: ComponentRef,
    emailFields: ComponentRef[]
  ) {
    const subscribeFields = await this._findConnectedComponentsByFieldType(
      controllerRef,
      FieldPreset.GENERAL_SUBSCRIBE
    )

    const isSubscribeFieldExistsWithoutEmailField =
      subscribeFields.length > 0 && emailFields.length == 0

    return isSubscribeFieldExistsWithoutEmailField
  }

  private async _isEmailFieldMissing(controllerRef: ComponentRef, emailFields: ComponentRef[]) {
    const formRef = await this.findConnectedComponent(controllerRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formRef)
    const hasGetSubscribersPlugin = _.chain(config).get('plugins').find({ id: FormPlugin.GET_SUBSCRIBERS }).value()

    if (!hasGetSubscribersPlugin) {
      return false
    }

    return emailFields.length === 0
  }

  public async isEmailFieldMissing(controllerRef) {
    const emailFields = await this._findConnectedComponentsByFieldType(
      controllerRef,
      FieldPreset.EMAIL
    )

    return (
      (await this._isSubscribeFieldExistsWithoutEmailField(controllerRef, emailFields)) ||
      (await this._isEmailFieldMissing(controllerRef, emailFields))
    )
  }

  public async isAppWidget(componentRef) {
    return (
      (await this.boundEditorSDK.components.getType({ componentRef })) ===
      'platform.components.AppWidget'
    )
  }

  public async handleDelete(
    componentRef: ComponentRef, //tslint:disable-line
    payload: ComponentConnection,
    historySnapshotId
  ) {
    const { controllerRef, role } = payload
    if (role === ROLE_FORM) {
      /*
      TEMP SOLUTION: When we delete form we will get in listConnectedComponents (few lines below) all components
        including other forms in other pages when user duplicated the page - we want to skip the implementation below
        when we got here with duplicated form the user didn't deleted manually
      TODO: Wrap the code below in if-statement when we will have the AppWidget
      `
        const connectedViaAppWidget = await this.boundEditorSDK.components.getType({ componentRef }) === '...AppWidget'
        if (!connectedViaAppWidget) {
          // Do the code below for backwards compatibility
        }
      `
      */
      const esi = await this.getEditorSessionId()
      this.biLogger.log({
        evid: EVENTS.EDITOR.DELETE_FORM,
        esi: esi,
        form_comp_id: componentRef.id,
        template: payload.config.preset,
      })

      const duplicatedForm = await this.findConnectedComponent(controllerRef, ROLE_FORM)
      if (duplicatedForm) {
        return
      }

      if (!(await this.isMobileMode())) {
        const connectedRefs = await this.boundEditorSDK.controllers.listConnectedComponents({
          controllerRef,
        })
        await Promise.all(
          connectedRefs.map(async connectedComponentRef =>
            this.boundEditorSDK.components.remove({ componentRef: connectedComponentRef })
          )
        )

        const isComponentExists = await this._isComponentExists(controllerRef)
        if (isComponentExists) {
          await this.boundEditorSDK.components.remove({ componentRef: controllerRef })
        }
        await this.boundEditorSDK.history.add({ label: 'History', historySnapshotId })
      }
    } else {
      // This is not working well with duplicated forms, it can return the wrong form component (depends who is first in the array)
      // Should be fixed with AppWidget
      const formComponentRef = await this.findConnectedComponent(controllerRef, ROLE_FORM)
      const {
        config: { fieldType },
      } = payload
      if (formComponentRef) {
        if (role === ROLE_SUBMIT_BUTTON) {
          const {
            config: { preset },
          } = await this.getComponentConnection(formComponentRef)
          const esi = await this.getEditorSessionId()
          await this._deleteSubmissionButton(formComponentRef, {
            startBi: {
              form_comp_id: formComponentRef.id,
              template: preset,
              esi,
            },
          })
        } else if (role === ROLE_MESSAGE) {
          const {
            config: { successActionType },
          } = await this.getComponentConnection(formComponentRef)
          if (
            this._shouldOpenTooltipForDeletedSuccessMessage(successActionType, formComponentRef)
          ) {
            await this._popRestoreNotification(
              formComponentRef,
              CriticalComponentTypes.HIDDEN_MESSAGE
            )
          }
        } else if (fieldType === FieldPreset.EMAIL) {
          const shouldRestoreNotification = await this.isEmailFieldMissing(controllerRef)

          if (shouldRestoreNotification) {
            this._popRestoreNotification(formComponentRef, CriticalComponentTypes.EMAIL_FIELD)
          }
        }

        // this.boundEditorSDK.selection.selectComponentByCompRef({
        //   compsToSelect: [this.isAppWidget(controllerRef) ? controllerRef : formComponentRef],
        // })
      }
    }
  }

  public async addMandatoryEmailField(formRef) {
    const {
      config: { preset },
    } = await this.getComponentConnection(formRef)
    const email: FieldProperties = getFieldProperties(FieldPreset.EMAIL)
    const commonStyles = await this.style.getFieldsCommonStylesGlobalDesign(formRef)

    this.fields.addField(formRef, preset, {
      commonStyles,
      extraData: {
        ...email.extraData,
        props: { required: true },
        connectionConfig: { crmType: CRM_TYPES.EMAIL, crmTag: CRM_TAGS.MAIN },
      },
      fieldType: FieldPreset.EMAIL,
    })
  }

  @withBi({ startEvid: EVENTS.EDITOR.DELETE_SUBMISSION_BUTTON })
  private async _deleteSubmissionButton(componentRef, _biData) {
    const componentType = await this._getDeletedComponentType(
      componentRef,
      CriticalComponentTypes.SUBMIT_BUTTON
    )
    this._popRestoreNotification(componentRef, componentType)
  }

  private async _getDeletedComponentType(
    componentRef: ComponentRef,
    deletedComponentType: CriticalComponentTypes
  ) {
    const isRegistrationForm = await this.isRegistrationForm(componentRef)
    if (isRegistrationForm) {
      if (deletedComponentType === CriticalComponentTypes.SUBMIT_BUTTON) {
        return CriticalComponentTypes.SIGNUP_BUTTON
      }
      if (deletedComponentType === CriticalComponentTypes.HIDDEN_MESSAGE) {
        return CriticalComponentTypes.REGISTRATION_FORM_MESSAGE
      }
    }
    return deletedComponentType
  }

  private async _popRestoreNotification(
    componentRef: ComponentRef,
    deletedComponentType: CriticalComponentTypes
  ) {
    const isMobileMode = await this.isMobileMode()
    const componentType = await this._getDeletedComponentType(componentRef, deletedComponentType)

    this.boundEditorSDK.editor
      .showNotification({
        title: translations.t(`quicktipDelete.title`),
        message: isMobileMode
          ? translations.t(`quicktipDelete.${componentType}.mobileText`)
          : translations.t(`quicktipDelete.${componentType}.text`),
        type: 'warning',
        link: {
          caption: isMobileMode ? '' : translations.t(`quicktipDelete.${componentType}.link`),
        },
      })
      .then(shouldRestore => {
        if (!shouldRestore) {
          return
        }
        switch (deletedComponentType) {
          case CriticalComponentTypes.SUBMIT_BUTTON:
          case CriticalComponentTypes.SIGNUP_BUTTON:
            this.settings.addSubmitButton(componentRef)
            break
          case CriticalComponentTypes.HIDDEN_MESSAGE:
          case CriticalComponentTypes.REGISTRATION_FORM_MESSAGE:
            this.settings.addHiddenMessage(componentRef)
            break
          case CriticalComponentTypes.EMAIL_FIELD:
            this.addMandatoryEmailField(componentRef)
            break
        }
      })
  }

  private _shouldOpenTooltipForDeletedSuccessMessage(
    successActionType: SuccessActionTypes,
    componentRef: ComponentRef
  ) {
    return (
      successActionType !== SuccessActionTypes.LINK &&
      successActionType !== SuccessActionTypes.DOWNLOAD_DOCUMENT &&
      successActionType !== SuccessActionTypes.EXTERNAL_LINK &&
      componentRef
    )
  }

  public async isCollectionExists(componentRef) {
    const {
      config: { collectionId },
    } = await this.getComponentConnection(componentRef)
    const validCollectionId = getValidCollectionId(componentRef.id, collectionId)
    return this.collectionsApi.isCollectionExists(validCollectionId)
  }

  public async getLocale() {
    return this.boundEditorSDK.environment.getLocale()
  }

  public async sendAllFormsData() {
    const controllers = await this.boundEditorSDK.controllers.listAllControllers()
    const collectionsById = await this.collectionsApi.getCollectionMapById()
    const forms = await Promise.all(
      _.map(controllers, async ({ controllerRef }) => {
        const formRef = await this.findConnectedComponent(controllerRef, ROLE_FORM)
        if (!formRef) {
          return
        }
        const { config } = await this.getComponentConnection(formRef)
        const validCollectionId = getValidCollectionId(formRef.id, config.collectionId)
        const fields = await this.fields.getFieldsSortByXY(formRef)
        const formattedPlugins = _.map(config.plugins, p => _.toUpper(_.snakeCase(p.id)))

        return {
          formId: formRef.id,
          formName: config.formName,
          collectionId: collectionsById[validCollectionId] && validCollectionId,
          plugins: formattedPlugins,
          fields: _.map(fields, field => {
            const fieldDef: FormFieldDefinition = {
              fieldId: field.componentRef.id,
              fieldName: field.crmLabel,
            }

            if (field.collectionFieldKey) {
              fieldDef.fieldCollectionKey = field.collectionFieldKey
            }

            return fieldDef
          }),
        }
      })
    )
    const formData = {
      forms: _.filter(forms, x => !!x),
    }
    return this.remoteApi.publishSite(formData)
  }

  public async isRegistrationForm(componentRef) {
    const formCompRef: ComponentRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formCompRef)
    const plugins: Plugin[] = _.get(config, 'plugins', [])
    return !!_.find(plugins, { id: FormPlugin.REGISTRATION_FORM })
  }

  public async getFormConfigData(componentRef) {
    const formCompRef: ComponentRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formCompRef)
    const plugins = _.map(_.get(config, 'plugins', []), 'id')
    const preset = _.get(config, 'preset')

    return { plugins, preset }
  }

  public async reportBiFirstSave(oldSiteId) {
    const controllers: {
      controllerRef: ComponentRef
    }[] = await this.boundEditorSDK.controllers.listAllControllers()
    const formRef: ComponentRef = await this.findConnectedComponent(
      controllers[0].controllerRef,
      ROLE_FORM
    )
    const {
      config: { preset },
    } = await this.getComponentConnection(formRef)
    this.biLogger.log({
      evid: EVENTS.EDITOR.USER_SAVE_TEMPLATE_WITH_FORM,
      form_comp_id: formRef.id,
      template: preset,
      templateId: oldSiteId,
    })
  }

  public async reportBiAppWidgetPasted(componentRef) {
    const formRef: ComponentRef = await this.getFormContainerOfAppWidget(componentRef)
    const {
      config: { preset },
    } = await this.getComponentConnection(formRef)
    this.biLogger.log({
      evid: EVENTS.EDITOR.PASTE_APP_WIDGET,
      source_name: 'copy form',
      form_comp_id: formRef.id,
      template: preset,
    })
  }

  public async createTag(tagName: string) {
    return this.remoteApi.createTag(tagName) // TODO move to add-form only
  }

  public async addHeightToContainers(componentRef: ComponentRef, extraHeight: number) {
    const ancestors = await this.boundEditorSDK.components.getAncestors({ componentRef })
    const heights = _.keyBy(
      await this.boundEditorSDK.components.get({
        componentRefs: [componentRef, ...ancestors],
        properties: ['layout'],
      }),
      'componentRef.id'
    )

    const addHeight = async (compRef: ComponentRef) => {
      return this.boundEditorSDK.components.layout.update({
        componentRef: compRef,
        layout: { height: heights[compRef.id].layout.height + extraHeight },
      })
    }

    await addHeight(componentRef)
    await Promise.all(_.map(ancestors, addHeight))
  }

  public async logFetchThemesFailed(compRef: ComponentRef | null, reason: string){
    const esi = await this.getEditorSessionId()
    this.biLogger.log({
      evid: EVENTS.EDITOR.FETCH_FAILED,
      esi,
      name: "global design",
      reason,
      form_comp_id: _.get(compRef, 'id')
    })
  }

  public async logFetchPresetsFailed(compRef: ComponentRef | null, reason: string){
    const esi = await this.getEditorSessionId()
    this.biLogger.log({
      evid: EVENTS.EDITOR.FETCH_FAILED,
      esi,
      name: "preset",
      reason,
      form_comp_id: _.get(compRef, 'id')
    })
  }

  public async panelGoBack(){

  }
}
