import { isAnyField, isInputField, undoable, withBi, getValidCollectionId } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { ComponentRef, FormField } from '../api-types'
import * as _ from 'lodash'
import CoreApi from '../core-api'
import {
  FIELDS_ROLES,
  ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG,
  ROLE_FORM,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
} from '../../../constants/roles'
import { CustomField, FieldBehaviorType, FieldPreset, FileUploaderType } from '../../../constants/field-types'
import { FormPreset } from '../../../constants/form-types'
import { createSuffixedName } from '../../../utils/utils'
import { createField } from '../services/form-service'
import { GROUP_COMPONENT, MOBILE_CONTAINER } from './consts/container-types'
import { commonStyles } from '../services/form-style-service'
import { FieldExtraData } from '../preset/fields/field-types-data'

export default class FeildSettingsApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private remoteApi: any
  private experiments: any

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.experiments = experiments
  }

  private async _getAncestorsWithTypes(
    componentRef,
    types: string[],
  ): Promise<{ type: string; componentRef: ComponentRef }[]> {
    const anscestors = await this.boundEditorSDK.components.getAncestors({ componentRef })
    const ancestorsWithTypes = await Promise.all<{ type: string; componentRef: ComponentRef }>(
      anscestors.map(async (componentAncestor: ComponentRef) => ({
        type: await this.boundEditorSDK.components.getType({ componentRef: componentAncestor }),
        componentRef: componentAncestor,
      })),
    )

    return ancestorsWithTypes.filter(ancestor => types.some(type => ancestor.type === type))
  }

  private async _getAllAncestorsWithTypes(componentRefs: ComponentRef[]) {
    const anscestorsOfComponents = await Promise.all(
      componentRefs.map(async componentRef => {
        const ancestors = await this.boundEditorSDK.components.getAncestors({ componentRef })

        return {
          componentRef,
          ancestors,
        }
      }),
    )

    const allAncestors = anscestorsOfComponents.reduce<ComponentRef[]>(
      (accumulator: ComponentRef[], { ancestors }) => accumulator.concat(ancestors),
      [],
    )

    const ancestorsWithTypes = await this.boundEditorSDK.components.get({
      componentRefs: allAncestors,
      properties: ['componentType'],
    })

    return anscestorsOfComponents.map(({ componentRef, ancestors }) => ({
      componentRef,
      ancestors: ancestors.map(ancestorComponentRef =>
        ancestorsWithTypes.find(
          ancestorWithType => ancestorComponentRef.id === ancestorWithType.componentRef.id,
        ),
      ),
    }))
  }

  private _sumOffsetsWithMap(containers: ComponentRef[], componentsLayoutMap) {
    const containersOffset = containers.reduce(
      (offsetAccumulator, currentValue) => {
        const containerLayout = componentsLayoutMap[currentValue.id].layout

        return {
          x: offsetAccumulator.x + containerLayout.x,
          y: offsetAccumulator.y + containerLayout.y,
        }
      },
      { x: 0, y: 0 },
    )

    return containersOffset
  }

  private async _sumOffsets(containers: ComponentRef[]) {
    const containersOffset = await containers.reduce(
      async (offsetAccumulatorPromise, currentValue) => {
        const offsetAccumulator = await offsetAccumulatorPromise
        const containerLayout = await this.boundEditorSDK.components.layout.get({
          componentRef: currentValue,
        })

        return {
          x: offsetAccumulator.x + containerLayout.x,
          y: offsetAccumulator.y + containerLayout.y,
        }
      },
      Promise.resolve({ x: 0, y: 0 }),
    )

    return containersOffset
  }

  public async getRawFields(componentRef) {
    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    return this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
  }

  /**
   * A test implementation that uses fewer editor API calls
   * @param componentRef
   * @param param1
   */
  private async _getFieldsSortByXY(
    componentRef: ComponentRef,
    { allFieldsTypes } = { allFieldsTypes: false },
  ) {
    const children = await this.getRawFields(componentRef)
    const rawFields = await this._getFields(children.filter(x => !!x), allFieldsTypes)
    const allAncestors = await this._getAllAncestorsWithTypes(
      rawFields.map(field => field.componentRef),
    )
    const ancestorsMap = _.keyBy(allAncestors, 'componentRef.id')
    const allComponents = allAncestors
      .reduce(
        (acc, current) => acc.concat(current.ancestors, [{ componentRef: current.componentRef }]),
        [],
      )
      .map(el => el.componentRef)
    const allComponentsLayout = await this.boundEditorSDK.components.get({
      componentRefs: allComponents,
      properties: ['layout'],
    })
    const componentsLayoutMap = _.keyBy(allComponentsLayout, 'componentRef.id')

    const fields = rawFields.map(field => {
      const parentContainers = ancestorsMap[field.componentRef.id].ancestors.filter(ancestor =>
        [MOBILE_CONTAINER, GROUP_COMPONENT].some(type => type === ancestor.componentType),
      )
      const fieldLayout = componentsLayoutMap[field.componentRef.id].layout || {
        x: 0,
        y: 0,
        height: 0,
        width: 0,
      }

      if (parentContainers.length > 0) {
        const containersOffset = this._sumOffsetsWithMap(
          parentContainers.map(container => container.componentRef),
          componentsLayoutMap,
        )

        fieldLayout.x += containersOffset.x
        fieldLayout.y += containersOffset.y
      }

      const { x, y, height, width } = fieldLayout
      return _.merge({ x, y, height, width }, field)
    })

    return _.sortBy(fields, ['y', 'x'])
  }

  public async getFieldsSortByXY(
    componentRef: ComponentRef,
    { allFieldsTypes } = { allFieldsTypes: false },
  ) {
    return this._getFieldsSortByXY(componentRef, { allFieldsTypes })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED })
  public async updateCrmLabel(componentRef: ComponentRef, crmLabel: string, _biData = {}) {
    const {
      config: { collectionFieldKey },
      controllerRef,
    } = await this.coreApi.getComponentConnection(componentRef)
    await this.coreApi.setComponentConnection(componentRef, { crmLabel })

    const updateCollection = async () => {
      const collectionId = await this._getCollectionId(controllerRef)
      if (!collectionId) {
        return
      }
      return this.coreApi.collectionsApi.updateField(collectionId, collectionFieldKey, crmLabel)
    }
    return updateCollection()
  }

  @undoable()
  public changeQuestion(componentRef: ComponentRef, question, fieldType) {
    switch (fieldType) {
      case FieldPreset.GENERAL_UPLOAD_BUTTON:
        return this._changeUploadFileLabel(componentRef, question)
      case FieldPreset.GENERAL_RADIO_BUTTON:
      case FieldPreset.GENERAL_CHECKBOX:
      case FieldPreset.GENERAL_SINGLE_CHECKBOX:
      case FieldPreset.GENERAL_SUBSCRIBE:
      case FieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS:
        return this._changeLabel(componentRef, question)
      default:
        return this._changePlaceholder(componentRef, question)
    }
  }

  @undoable()
  public async changeLabel(componentRef: ComponentRef, label: string) {
    return this._changeLabel(componentRef, label)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED })
  public async showLabelChanged(componentRef: ComponentRef, showLabel: boolean, _biData = {}) {
    await this._showLabelChanged(componentRef, showLabel)
  }

  private async _showLabelChanged(componentRef: ComponentRef, showLabel: boolean) {
    if (showLabel) {
      const {
        config: { label },
      } = await this.coreApi.getComponentConnection(componentRef)

      await this.boundEditorSDK.components.data.update({
        componentRef,
        data: { label },
      })
    } else {
      await this.boundEditorSDK.components.data.update({
        componentRef,
        data: { label: '' },
      })
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED })
  public async showLabelChangedForAllFields(fields, _biData = {}) {
    fields.forEach(async field => {
      const { showLabel, componentRef } = field
      await this._showLabelChanged(componentRef, showLabel)
    })
  }

  @undoable()
  public changeUploadFileLabel(componentRef: ComponentRef, buttonLabel) {
    this._changeUploadFileLabel(componentRef, buttonLabel)
  }

  @undoable()
  public changePlaceholder(componentRef: ComponentRef, placeholder: string) {
    return this._changePlaceholder(componentRef, placeholder)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.TOGGLE_REQUIRED_FIELD })
  public changeRequired(componentRef: ComponentRef, required: boolean, _biData = {}) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { required },
    })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.TOGGLE_REQUIRED_FIELD })
  public updatedCheckedByDefault(componentRef, checked, _biData = {}) {
    return this.boundEditorSDK.components.data.update({ componentRef, data: { checked } })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.SELECT_FIELD_TO_CONNECT })
  public setComponentConnection(connectToRef: ComponentRef, connectionConfig, _biData = {}) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  public getCustomFields() {
    return this.remoteApi.getCustomFields()
  }

  public createCustomField(field: CustomField) {
    return this.remoteApi.createCustomField(field)
  }

  private async _getFields(
    componentRefs: ComponentRef[],
    allFieldsTypes: boolean = false,
  ): Promise<FormField[]> {
    if (componentRefs.length === 0) {
      return []
    }

    const isValidFieldPred: (role: string) => boolean = allFieldsTypes ? isAnyField : isInputField

    const components = await this.boundEditorSDK.components.get({
      componentRefs: componentRefs,
      properties: ['props', 'data', 'connections'],
    })

    const fields = await Promise.all<FormField>(
      components.map(async component => {
        const { componentRef } = component
        const {
          config: {
            crmLabel,
            crmType,
            crmTag,
            customFieldId,
            customFieldName,
            fieldType,
            collectionFieldKey,
            collectionFieldType,
            label: labelFromConnection,
          },
          role,
        } = component.connections.find(connection => connection.isPrimary)

        if (!isValidFieldPred(role)) {
          return null
        }

        const {
          props: { placeholder: propPlaceholder, required, dateFormat },
          data: { placeholder, buttonLabel, label: labelFromData, checked },
        } = component

        const label = labelFromData || labelFromConnection
        const defaultLabel = this._getDefaultLabel({
          buttonLabel,
          label,
          placeholder: placeholder || propPlaceholder,
          fieldType,
        })

        await this._updateLabelConnection({
          componentRef,
          label: labelFromData,
          defaultLabel,
          labelFromConnection,
        })

        return {
          componentRef,
          crmLabel,
          required,
          crmType,
          crmTag,
          fieldType,
          customFieldId,
          customFieldName,
          collectionFieldKey,
          collectionFieldType,
          checked,
          role,
          label: label || defaultLabel,
          placeholder,
          showLabel: !!labelFromData,
          buttonLabel,
          dateFormat
        }
      }),
    )

    return fields.filter(x => !!x)
  }

  public async getField(
    componentRef: ComponentRef,
    allFieldsTypes: boolean = false,
  ): Promise<FormField> {
    const {
      config: {
        crmLabel,
        crmType,
        crmTag,
        customFieldId,
        fieldType,
        collectionFieldKey,
        collectionFieldType,
        label: labelFromConnection,
      },
      role,
    } = await this.coreApi.getComponentConnection(componentRef)

    const isValidFieldPred: (role: string) => boolean = allFieldsTypes ? isAnyField : isInputField
    if (!isValidFieldPred(role)) {
      return null
    }

    const {
      props: { placeholder: propPlaceholder, required },
      data: { placeholder, buttonLabel, label: labelFromData, checked },
    } = await this._getFieldPropertiesAndData(componentRef)

    const label = labelFromData || labelFromConnection
    const defaultLabel = this._getDefaultLabel({
      buttonLabel,
      label,
      placeholder: placeholder || propPlaceholder,
      fieldType,
    })

    await this._updateLabelConnection({
      componentRef,
      label: labelFromData,
      defaultLabel,
      labelFromConnection,
    })

    return {
      componentRef,
      crmLabel,
      required,
      crmType,
      crmTag,
      fieldType,
      customFieldId,
      collectionFieldKey,
      collectionFieldType,
      checked,
      role,
      label: label || defaultLabel,
      placeholder,
      showLabel: !!labelFromData,
      buttonLabel,
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.manageFieldsPanel.DUPLICATE_FIELD })
  public async duplicateField(
    componentRef: ComponentRef,
    field: FormField,
    preset: FormPreset,
    { extraData, commonStyles },
    _biData = {},
  ) {
    const [
      {
        style,
        data,
        layout: { height, width },
      },
    ] = await this.boundEditorSDK.components.get({
      componentRefs: [field.componentRef],
      properties: ['style', 'data', 'layout'],
    })
    const { connectToRef, controllerRef } = await this._addField(componentRef, preset, {
      fieldType: field.fieldType,
      extraData: _.merge({}, extraData, {
        data,
        layout: { height, width },
      }),
      commonStyles,
    })
    await this.boundEditorSDK.components.style.update({
      componentRef: connectToRef,
      style: _.get(style, 'style.properties'),
    })
    return { connectToRef, controllerRef }
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.addFieldPanel.SELECT_FIELD_TO_ADD,
    endEvid: EVENTS.PANELS.addFieldPanel.ADD_FIELD_COMPLETE,
  })
  public async addField(
    componentRef: ComponentRef,
    preset: FormPreset,
    {
      fieldType,
      extraData,
      commonStyles,
      fieldBehaviorType = FieldBehaviorType.INPUT,
    }: {
      fieldType: FieldPreset
      extraData: FieldExtraData
      commonStyles: commonStyles
      fieldBehaviorType?: FieldBehaviorType
    },
    _biData = {},
  ) {
    return this._addField(componentRef, preset, {
      fieldType,
      extraData,
      commonStyles,
      fieldBehaviorType,
    })
  }

  public async restoreField(formRef: ComponentRef, { data, role, config }) {
    const { controllerRef } = await this.coreApi.getComponentConnection(formRef)
    const field = { data, role, connectionConfig: config }
    return this.coreApi.addComponentAndConnect(field, controllerRef, formRef)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED })
  public async changeCheckboxLink(componentRef: ComponentRef, _biData = {}) {
    const { link: previousLink } = await this.boundEditorSDK.components.data.get({ componentRef })

    const link = await this.boundEditorSDK.editor.openLinkPanel({
      value: previousLink,
    })

    this.boundEditorSDK.components.data.update({ componentRef, data: { link } })
    const linkLocationValue = await this.boundEditorSDK.editor.utils.getLinkAsString({ link })

    return { link, linkLocationValue }
  }

  @undoable()
  public changeCheckboxLinkLabel(componentRef: ComponentRef, linkLabel: string) {
    return this.boundEditorSDK.components.data.update({ componentRef, data: { linkLabel } })
  }

  public async getCheckboxLinkData(componentRef: ComponentRef) {
    const { link, linkLabel } = await this.boundEditorSDK.components.data.get({ componentRef })
    const linkLocationValue = link
      ? await this.boundEditorSDK.editor.utils.getLinkAsString({ link })
      : null

    return { link, linkLocationValue, linkLabel }
  }

  public async updateCheckboxLink(componentRef: ComponentRef, link) {
    this.boundEditorSDK.components.data.update({ componentRef, data: { link } })
  }

  @undoable()
  public removeCheckboxLinkData(componentRef: ComponentRef) {
    const emptyLinkData = { link: null, linkLabel: '' }
    return this.boundEditorSDK.components.data.update({ componentRef, data: emptyLinkData })
  }

  private async _findNewFieldLayout(componentRef: ComponentRef) {
    const childLayouts = await this.coreApi.layout.getChildrenLayouts(componentRef, FIELDS_ROLES)
    const lastLayout: any = _.maxBy(childLayouts, (field: any) => field.y)
    return {
      x: lastLayout ? lastLayout.x : 60,
      y: lastLayout ? lastLayout.y + lastLayout.height + 32 : 60,
    }
  }

  private async _addField(
    componentRef: ComponentRef,
    preset,
    {
      fieldType,
      extraData,
      commonStyles,
      fieldBehaviorType = FieldBehaviorType.INPUT,
    }: {
      fieldType: FieldPreset
      extraData: FieldExtraData
      commonStyles: commonStyles
      fieldBehaviorType?: FieldBehaviorType
    },
  ) {
    const {
      controllerRef,
      config: { collectionId },
    } = await this.coreApi.getComponentConnection(componentRef)
    const validCollectionId = getValidCollectionId(componentRef.id, collectionId)
    const layout = await this._findNewFieldLayout(componentRef)
    const fields = await this.getFieldsSortByXY(componentRef)
    if (fieldBehaviorType === FieldBehaviorType.INPUT) {
      const collectionFieldKey = createSuffixedName(
        _.map(fields, 'collectionFieldKey'),
        _.camelCase(_.get(extraData, 'connectionConfig.crmLabel')),
        '',
      )
      _.set(extraData, 'connectionConfig.collectionFieldKey', collectionFieldKey)
    }
    const field = createField(preset, { fieldType, extraData, commonStyles }, layout)
    const { connectToRef } = await this.coreApi.addComponentAndConnect(
      field,
      controllerRef,
      componentRef,
    )

    const fieldLayout = await this.boundEditorSDK.components.layout.get({
      componentRef: connectToRef,
    })

    const updateButtonPosition = async () => {
      const buttons = await this.coreApi.layout.getChildrenLayouts(componentRef, ROLE_SUBMIT_BUTTON)
      const submitBtn: any = buttons[0]
      return (
        submitBtn &&
        this.boundEditorSDK.components.layout.update({
          componentRef: submitBtn.componentRef,
          layout: { y: submitBtn.y + fieldLayout.height + 32 },
        })
      )
    }

    const updateBoxHeight = () =>
      this.coreApi.addHeightToContainers(componentRef, fieldLayout.height + 32)

    const updateMessagePosition = async () => {
      const messages = await this.coreApi.layout.getChildrenLayouts(componentRef, ROLE_MESSAGE)
      const message: any = messages[0]
      return (
        message &&
        this.boundEditorSDK.components.layout.update({
          componentRef: message.componentRef,
          layout: { y: message.y + fieldLayout.height + 32 },
        })
      )
    }

    const updateLoginDialogLinkPosition = async () => {
      const loginDialogLinks = await this.coreApi.layout.getChildrenLayouts(
        componentRef,
        ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG,
      )
      const loginDialogLink: any = loginDialogLinks[0]
      return (
        loginDialogLink &&
        this.boundEditorSDK.components.layout.update({
          componentRef: loginDialogLink.componentRef,
          layout: { y: loginDialogLink.y + fieldLayout.height + 32 },
        })
      )
    }

    const addFieldToCollection = () =>
      this.coreApi.collectionsApi.addFieldToCollection(validCollectionId, field.connectionConfig)

    const recenterInLightboxIfNeeded = async () => {
      if (await this.coreApi.isRegistrationForm(componentRef)) {
        return this.coreApi.layout.centerComponentInsideLightbox(componentRef)
      }
    }

    await updateBoxHeight()

    await Promise.all([
      updateLoginDialogLinkPosition(),
      updateButtonPosition(),
      updateMessagePosition(),
      addFieldToCollection(),
    ]).then(recenterInLightboxIfNeeded)

    return { connectToRef, controllerRef }
  }

  private _changePlaceholder(componentRef: ComponentRef, placeholder) {
    const updatePropPlaceholderPromise = this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { placeholder },
    })
    const updateDataPlaceholderPromise = this.boundEditorSDK.components.data.update({
      componentRef,
      data: { placeholder },
    })

    return Promise.all([updatePropPlaceholderPromise, updateDataPlaceholderPromise])
  }

  private _changeUploadFileLabel(componentRef: ComponentRef, buttonLabel) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { buttonLabel },
    })
  }

  private async _changeLabel(componentRef: ComponentRef, label: string) {
    await this.boundEditorSDK.components.data.update({
      componentRef,
      data: { label },
    })
    return this.coreApi.setComponentConnection(componentRef, { label })
  }

  private async _getCollectionId(controllerRef) {
    const componentRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
    if (!componentRef) {
      return
    }
    const {
      config: { collectionId },
    } = await this.coreApi.getComponentConnection(componentRef)
    return getValidCollectionId(componentRef.id, collectionId)
  }

  private async _getFieldPropertiesAndData(componentRef: ComponentRef) {
    const res = await this.boundEditorSDK.components.get({
      componentRefs: componentRef,
      properties: ['props', 'data'],
    })
    return res[0]
  }

  private _updateLabelConnection({ componentRef, label, defaultLabel, labelFromConnection }) {
    if (labelFromConnection) {
      return
    }

    return this.setComponentConnection(componentRef, { label: label || defaultLabel })
  }

  private _getDefaultLabel({ buttonLabel, label, placeholder, fieldType }) {
    switch (fieldType) {
      case FieldPreset.GENERAL_UPLOAD_BUTTON:
        return label || buttonLabel
      case FieldPreset.GENERAL_RATING:
        return
      default:
        return label || _.get(placeholder, 'text') || placeholder
    }
  }

  public onDateFormatChange(componentRef: ComponentRef, newFormat: string) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { dateFormat: newFormat },
    })
  }

  public onFileUploaderTypeChanged(componentRef: ComponentRef, newType: FileUploaderType) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { filesType: newType },
    })
  }
}
