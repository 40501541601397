import * as _ from 'lodash'
import { FieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { generalTextData } from '../../general-fields/definitions/general-text'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

const makeLastName = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalTextData.make(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.LAST_NAME,
        crmLabel: t(`fieldTypes.${FieldPreset.LAST_NAME}`),
      },
      props: { placeholder: t(`fieldTypes.${FieldPreset.LAST_NAME}`) },
      data: {
        maxLength: 100,
        placeholder: t(`fieldTypes.${FieldPreset.LAST_NAME}`),
      },
    },
  })
}

export const lastNameData: FieldData = {
  make: makeLastName,
  customFields: [],
  icon: iconNames.name
}
