import * as _ from 'lodash'
import { createBoxManifest } from './box-manifest'
import { createFieldsManifests } from './field-manifest'
import { FIELDS_ROLES, ROLE_FORM, ROLE_MESSAGE, ROLE_SUBMIT_BUTTON, ROLE_TITLE, } from '../../../constants/roles'
import { createSubmitButtonManifest } from './submit-button-manifest'
import { createHiddenMessageManifest } from './hidden-message-manifest'
import { createTitleManifest } from './title-manifest'
import { AppStateBuilder, AppStateObject } from '../app-state/app-state-builder'
import { FormPlugin } from '../../../constants/plugins'

const ALWAYS_HIDE_CONTROLLER = 'NEVER'
const states = _.flatMap([true, false], isTopPremium =>
  _.flatMap(
    [FormPlugin.FORM_BUILDER, FormPlugin.GET_SUBSCRIBERS, FormPlugin.REGISTRATION_FORM],
    plugin =>
      _.flatMap([true, false], duplicatable =>
        _.flatMap([true, false], addGfpp =>
          new AppStateBuilder({
            duplicatable,
            addGfpp,
            isTopPremium,
            plugins: [plugin],
          })
        )
      )
  )
).filter(x => x.toString())

export const getAppManifest = () => {
  const fieldManifests = FIELDS_ROLES.reduce(
    (res, fieldRole) => ({
      ...res,
      [fieldRole]: createFieldsManifests()[fieldRole],
    }),
    {}
  )
  const titleManifest = createTitleManifest()
  const createManifest = (state: AppStateObject) => ({
    visibility: ALWAYS_HIDE_CONTROLLER,
    ...createBoxManifest(state),
    connections: {
      [ROLE_FORM]: createBoxManifest(state),
      [ROLE_SUBMIT_BUTTON]: createSubmitButtonManifest(state),
      [ROLE_MESSAGE]: createHiddenMessageManifest(state),
      [ROLE_TITLE]: titleManifest,
      ...fieldManifests,
    },
  })

  return {
    controllersStageData: {
      singlePostController: {
        default: createManifest({}),
        ...states.reduce((agg, state) => {
          agg[state.toString()] = createManifest(state.get())
          return agg
        }, {}),
      },
    },
  }
}
