import { FieldPreset } from '../../../../../constants/field-types'
import { firstNameData } from './definitions/first-name'
import { lastNameData } from './definitions/last-name'
import { companyData } from './definitions/company'
import { positionData } from './definitions/position'
import { emailData } from './definitions/email'
import { addressData } from './definitions/address'
import { phoneData } from './definitions/phone'
import { birthdayData } from './definitions/birthady'
import { anniversaryData } from './definitions/anniversary'
import { websiteData } from './definitions/webstie'
import { crmTextData } from './definitions/crm-txt'
import { crmNumberData } from './definitions/crm-number'
import { crmUrlData } from './definitions/crm-url'
import { crmDateData } from './definitions/crm-date'

export const recommendedFields = () => ({
  [FieldPreset.FIRST_NAME]: firstNameData,
  [FieldPreset.LAST_NAME]: lastNameData,
  [FieldPreset.COMPANY]: companyData,
  [FieldPreset.POSITION]: positionData,
  [FieldPreset.EMAIL]: emailData,
  [FieldPreset.ADDRESS]: addressData,
  [FieldPreset.PHONE]: phoneData,
  [FieldPreset.BIRTHDAY]: birthdayData,
  [FieldPreset.ANNIVERSARY]: anniversaryData,
  [FieldPreset.WEBSITE]: websiteData,
  [FieldPreset.CRM_TEXT]: crmTextData,
  [FieldPreset.CRM_NUMBER]: crmNumberData,
  [FieldPreset.CRM_URL]: crmUrlData,
  [FieldPreset.CRM_DATE]: crmDateData,
})
