import { undoable, withBi, isInputField } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { ComponentRef, FormField } from '../api-types'
import { ROLE_MESSAGE, ROLE_SUBMIT_BUTTON, ROLE_TITLE } from '../../../constants/roles'
import * as _ from 'lodash'
import {
  FIELD_MARGIN,
  FORM_PADDING,
  PADDING_FROM_TITLE,
  ROLE_PADDING,
  TOP_PADDING,
} from './consts/layout-settings'
import { EXTRA_COLSPANS } from './consts/columns'
import CoreApi from '../core-api'
import { getFieldsLayout } from './consts/layout-settings'

export default class LayoutApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi

  constructor(boundEditorSDK, coreApi: CoreApi, { biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
  }

  public updateFieldLayout(fieldRef: ComponentRef, newLayout) {
    return this.boundEditorSDK.components.layout.update({
      componentRef: fieldRef,
      layout: newLayout,
    })
  }

  public async updateFormWidth(componentRef: ComponentRef, width: number, spacing: number) {
    const prevWidth = (await this.boundEditorSDK.components.layout.get({
      componentRef,
    })).width

    const fields = await this.coreApi.fields.getFieldsSortByXY(componentRef, {
      allFieldsTypes: true,
    })
    const inputFields = fields.filter(field => isInputField(field.role))
    const isFieldsSharingTheRow = (field, index) =>
      index > 0 && field.y === inputFields[index - 1].y

    const rows: FormField[][] = inputFields.reduce((acc, curr, index) => {
      if (isFieldsSharingTheRow(curr, index)) {
        acc[acc.length - 1] = acc[acc.length - 1].concat(curr)
      } else {
        acc = acc.concat([[curr]])
      }
      return acc
    }, [])

    const fieldsUpdates = []
    const lastY = rows.reduce((prevY, row) => {
      const fieldsInRow = row.length
      const elementWidth = (width - spacing * (fieldsInRow - 1)) / fieldsInRow

      const firstElement = _.first(row)
      const newY = prevY >= 0 ? prevY + spacing : firstElement.y

      row.reduce((prevX, field) => {
        const newX = prevX >= 0 ? prevX + spacing + elementWidth : field.x
        fieldsUpdates.push(
          this.boundEditorSDK.components.layout.update({
            componentRef: field.componentRef,
            layout: { width: elementWidth, x: newX, y: newY },
          }),
        )
        return newX
      }, -1)

      return newY + firstElement.height
    }, -1)

    const lastElement = _.last(rows)[0]
    const heightDiff = lastElement.y + lastElement.height - lastY
    const widthDiff = prevWidth - width

    await Promise.all([
      ...fieldsUpdates,
      ...(await this._fixButtonAndMessage(
        componentRef,
        heightDiff,
        widthDiff,
        prevWidth,
        width,
        lastElement.x
      )),
    ])

    this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: { width },
    })
  }

  private async _fixButtonAndMessage(
    formRef: ComponentRef,
    heightDiff: number,
    widthDiff: number,
    prevWidth: number,
    newWidth: number,
    lastFieldX: number,
  ) {
    const layouts = await this.getChildrenLayouts(formRef, [ROLE_SUBMIT_BUTTON, ROLE_MESSAGE])
    const { x: buttonX, y: buttonY, width: buttonWidth, componentRef: buttonCompRef } = <FormField>(
      layouts.find(field => (<FormField>field).role === ROLE_SUBMIT_BUTTON)
    )
    const { y: messageY, componentRef: messageCompRef, width: messageWidth } = <FormField>(
      layouts.find(field => (<FormField>field).role === ROLE_MESSAGE)
    )

    const calcXDecrease = (elmX, elmWidth) => {
      const leftSpace = elmX - lastFieldX
      const sidesRadio = leftSpace / (prevWidth - elmWidth)
      return widthDiff * sidesRadio
    }

    let buttonPromise
    if (buttonWidth === prevWidth) {
      buttonPromise = this.boundEditorSDK.components.layout.update({
        componentRef: buttonCompRef,
        layout: { width: newWidth, y: buttonY - heightDiff },
      })
    } else {
      buttonPromise = this.boundEditorSDK.components.layout.update({
        componentRef: buttonCompRef,
        layout: { x: buttonX - calcXDecrease(buttonX, buttonWidth), y: buttonY - heightDiff },
      })
    }

    const messagePromise = this.boundEditorSDK.components.layout.update({
      componentRef: messageCompRef,
      layout: { width: newWidth * (messageWidth / prevWidth), y: messageY - heightDiff },
    })

    return [buttonPromise, messagePromise]
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.formLayoutPanel.CHANGE_LAYOUT,
    endEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED,
  })
  public async updateFieldsLayout(
    componentRef: ComponentRef,
    columnsNumber: number,
    { isAdiLayout = false, reorderedFields = null, showTitles = false } = {}, _biData = {})
    : Promise<void> {

    const fields = reorderedFields || await this.coreApi.fields.getFieldsSortByXY(componentRef, {
      allFieldsTypes: true,
    })
    const FIELDS_LAYOUT = getFieldsLayout(isAdiLayout, showTitles)

    const titleHeight = await this.updateComponentByTitle(componentRef)
    const layoutCalc = await this._calcUpdateFields(
      componentRef,
      fields,
      columnsNumber,
      titleHeight,
      FIELDS_LAYOUT,
    )
    const { height: oldHeight } = await this.boundEditorSDK.components.layout.get({ componentRef })

    const newHeight = layoutCalc.y + layoutCalc.maxHeight + FIELDS_LAYOUT.lastFieldPadding

    if (oldHeight < newHeight) {
      await this.coreApi.addHeightToContainers(componentRef, newHeight - oldHeight)
    }
    await Promise.all([
      ...layoutCalc.updates.map(({ componentRef, layout }) =>
        this.boundEditorSDK.components.layout.update({
          componentRef,
          layout,
        })
      ),
      this.updateComponentLayoutByRole(
        componentRef,
        ROLE_SUBMIT_BUTTON,
        ROLE_PADDING.SUBMIT,
        layoutCalc
      ),
      this.updateComponentLayoutByRole(
        componentRef,
        ROLE_MESSAGE,
        ROLE_PADDING.MESSAGE,
        layoutCalc
      ),
    ])

    if (oldHeight > newHeight) {
      await this.coreApi.addHeightToContainers(componentRef, newHeight - oldHeight)
    }

    await this.coreApi.setComponentConnection(componentRef, { columns: columnsNumber })
  }

  public async updateComponentByTitle(componentRef: ComponentRef): Promise<number> {
    const titleComponentRef = await this.coreApi.findComponentByRole(componentRef, ROLE_TITLE)
    if (!titleComponentRef) {
      return 0
    }

    const { height } = await this.boundEditorSDK.components.layout.get({
      componentRef: titleComponentRef,
    })
    await this.boundEditorSDK.components.layout.update({
      componentRef: titleComponentRef,
      layout: { x: FORM_PADDING, y: TOP_PADDING },
    })

    return height
  }

  public async getChildrenLayouts(componentRef: ComponentRef, childRoles: string[] | string) {
    if (_.isString(childRoles)) {
      childRoles = [<string>childRoles]
    }
    const pred = role => _.includes(childRoles, role)

    const childComps = await this.boundEditorSDK.components.getChildren({ componentRef })
    const childrenLayouts = await Promise.all(
      childComps.map(async child => {
        const { role } = await this.coreApi.getComponentConnection(child)
        if (pred(role)) {
          const layout = await this.boundEditorSDK.components.layout.get({ componentRef: child })
          return { componentRef: child, role, ...layout }
        }
        return null
      })
    )
    return _.filter(childrenLayouts, x => !!x)
  }

  public async updateComponentLayoutByRole(
    componentRef: ComponentRef,
    role: string,
    padding: number,
    layout
  ) {
    const roleLayouts = await this.getChildrenLayouts(componentRef, role)
    const firstRoleComponentRef = _.get(roleLayouts, [0, 'componentRef'])

    return this.updateYWithPadding(firstRoleComponentRef, padding, layout)
  }

  public updateYWithPadding(componentRef: ComponentRef, padding, { y, maxHeight }, key = 'y') {
    return this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: { [key]: y + maxHeight + padding },
    })
  }

  public async centerComponentInsideLightbox(componentRef: ComponentRef) {
    const { width, height, x, y } = await this.boundEditorSDK.components.layout.get({
      componentRef,
    })

    return this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: {
        x: _.max([0, x - width / 2]),
        y: _.max([0, y - height / 2]),
      },
    })
  }

  private _calcUpdateFields(
    componentRef: ComponentRef,
    fields,
    columnsNumber: number,
    titleHeight: number = 0,
    FIELDS_LAYOUT,
  ) {
    const reduceFunc = async (prevFieldLayout, field) => {
      const { updates, startX, x, y, maxHeight, defaultWidth, currentCol } = await prevFieldLayout
      const colSize = await this._getFieldColSize(field.componentRef, columnsNumber)
      const width = colSize * defaultWidth + (colSize - 1) * FIELD_MARGIN
      let nextCol = currentCol + colSize
      let layout = { width, x: x + width + FIELD_MARGIN, y }
      let overrideMaxHeight = -1
      if (nextCol > columnsNumber) {
        nextCol = colSize
        layout = { width, x: startX, y: y + maxHeight + FIELDS_LAYOUT.heightPadding }
        overrideMaxHeight = 0
      }

      return {
        updates: [
          ...updates,
          {
            componentRef: field.componentRef,
            layout,
          },
        ],
        startX,
        x: layout.x,
        y: layout.y,
        width,
        defaultWidth,
        maxHeight: _.max([overrideMaxHeight > -1 ? overrideMaxHeight : maxHeight, field.height]),
        currentCol: nextCol,
      }
    }

    const getBoxConfig = async (
      componentRef: ComponentRef,
      columns: number,
      titleHeight: number
    ) => {
      const { width } = await this.boundEditorSDK.components.layout.get({ componentRef })
      const titleExtraHeight = titleHeight > 0 ? titleHeight + PADDING_FROM_TITLE : 0

      return {
        updates: [],
        startX: FIELDS_LAYOUT.startX,
        currentCol: columns,
        x: FIELDS_LAYOUT.formPadding,
        y: 0,
        width: 0,
        maxHeight: FIELDS_LAYOUT.formPadding + titleExtraHeight - FIELD_MARGIN,
        defaultWidth: FIELDS_LAYOUT.defaultWidth(width, columns),
      }
    }

    return _.reduce(fields, reduceFunc, getBoxConfig(componentRef, columnsNumber, titleHeight))
  }

  private async _getFieldColSize(componentRef: ComponentRef, columns: number): Promise<number> {
    const type = await this.boundEditorSDK.components.getType({ componentRef })
    return _.min([EXTRA_COLSPANS[type] || 0, columns - 1]) + 1
  }
}
