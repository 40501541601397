import * as _ from 'lodash'
import { recommendedFields } from './recommended-fields'
import { generalFields } from './general-fields'
import { registrationFormFields } from './registration-form-fields'
import { FieldCollectionType } from '../../../../constants/wixcode'
import { COMPONENT_TYPES } from './component-types'
import { FieldPreset, customTypes } from '../../../../constants/field-types'
import { iconNames } from './icons/types'
import { FieldRenderConfigType } from './constants'
import { FormPlugin } from '../../../../constants/plugins'

export interface FieldExtraData {
  role: string
  connectionConfig?: {
    crmType: string
    crmLabel?: string
    crmTag?: string
  }
  props?: {}
  data?: {}
}

export interface FieldProperties {
  collectionFieldType?: FieldCollectionType | false
  componentType: COMPONENT_TYPES
  extraData: FieldExtraData
}

export interface FieldRenderConfigStructure {
  isMandatory?: boolean
  crmSync?: FieldRenderConfigType
  required?: FieldRenderConfigType
  internalName?: FieldRenderConfigType
  checkedByDefault?: FieldRenderConfigType
}

export type FieldRenderConfig = { [key in FormPlugin]?: FieldRenderConfigStructure }

const DEFAULT_FIELD_RENDER_CONFIG: FieldRenderConfigStructure = {
  isMandatory: false,
  crmSync: FieldRenderConfigType.ENABLED,
  required: FieldRenderConfigType.ENABLED,
  internalName: FieldRenderConfigType.ENABLED,
  checkedByDefault: FieldRenderConfigType.ENABLED,
}

export interface FieldData {
  make: () => FieldProperties
  customFields: customTypes[]
  icon: iconNames
  renderConfig?: FieldRenderConfig
}

const getFieldData = (fieldType: FieldPreset): FieldData => {
  return getAllFieldsData()[fieldType]
}

export const getAllFieldsData = () => ({
  ...recommendedFields(),
  ...generalFields(),
  ...registrationFormFields(),
})

export const getFieldProperties = (fieldType: FieldPreset): FieldProperties => {
  return getFieldData(fieldType).make()
}

export const getFieldCustomFields = (fieldType: FieldPreset): customTypes[] => {
  return getFieldData(fieldType).customFields
}

export const getFieldRenderConfigFields = (
  plugins: FormPlugin[],
  fieldType: FieldPreset,
  defaults = {}
): FieldRenderConfigStructure => {
  const config = getFieldData(fieldType).renderConfig
  const plugin = _.first(plugins) // current implementation support only 1 plugin per preset, change this when we will have more than 1

  if (config && plugin && config[plugin]) {
    return _.merge({}, DEFAULT_FIELD_RENDER_CONFIG, config[plugin])
  } else {
    return _.merge({}, DEFAULT_FIELD_RENDER_CONFIG, defaults)
  }
}

export const getFieldIconName = (fieldType: FieldPreset): iconNames => {
  return getFieldData(fieldType).icon
}
