import { FieldPreset, customTypes  } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

export const makeGeneralRadioGroupOption = () => ({
  type: 'RadioButton',
  metaData: {
    isPreset: false,
    schemaVersion: '1.0',
    isHidden: false,
  },
  value: '',
  label: '',
})

const makeGeneralRadioButton = (): FieldProperties => {
  const t = translations.t.bind(translations)
  return {
    collectionFieldType: FieldCollectionType.TEXT,
    componentType: COMPONENT_TYPES.RADIO_GROUP,
    extraData: {
      role: FIELDS.ROLE_FIELD_RADIO_LIST,
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
        crmLabel: t(`fieldTypes.${FieldPreset.GENERAL_RADIO_BUTTON}`),
      },
      data: {
        label: t(`preset.${FieldPreset.GENERAL_RADIO_BUTTON}Label`),
        value: t(`preset.${FieldPreset.GENERAL_RADIO_BUTTON}FirstOptionsValue`),
        defaultValue: t(`preset.${FieldPreset.GENERAL_RADIO_BUTTON}FirstOptionsValue`),
        options: [
          {
            ...makeGeneralRadioGroupOption(),
            value: t(`preset.${FieldPreset.GENERAL_RADIO_BUTTON}FirstOptionsValue`),
            label: t(`preset.${FieldPreset.GENERAL_RADIO_BUTTON}FirstOptionsLabel`),
          },
          {
            ...makeGeneralRadioGroupOption(),
            value: t(`preset.${FieldPreset.GENERAL_RADIO_BUTTON}SecondOptionsValue`),
            label: t(`preset.${FieldPreset.GENERAL_RADIO_BUTTON}SecondOptionsLabel`),
          },
          {
            ...makeGeneralRadioGroupOption(),
            value: t(`preset.${FieldPreset.GENERAL_RADIO_BUTTON}ThirdOptionsValue`),
            label: t(`preset.${FieldPreset.GENERAL_RADIO_BUTTON}ThirdOptionsLabel`),
          },
        ],
      },
    },
  }
}

export const generalRadioButtonData: FieldData = {
  make: makeGeneralRadioButton,
  customFields: [customTypes.TEXT],
  icon: iconNames.radioButton
}
