import 'regenerator-runtime/runtime'
import 'isomorphic-fetch'
import { getAppManifest as coreGetAppManifest } from './core/manifests/app-manifest'
import { getAppVersion, getSentryDSN } from '../utils/utils'
import * as _ from 'lodash'
import * as Raven from 'raven-js'
import { initApp, startApp } from './editor-ready/editor-ready'
import {
  handleComponentDelete,
  handleFirstSave,
  handleGfppClicked,
  handlePublish,
  handlePresetChanged,
  handleAppWidgetGfppClicked,
  handleAppWidgetPasted
} from './on-event/on-event'
import { fetcher, generateExportedApi } from './utils/utils'
import { EDITOR_EVENTS } from './constans/EVENTS'

Raven.config(getSentryDSN(), {
  logger: 'logger-editor-app', // TODO: We need to create another logger to ADI or somehow know in sentry that some errors come from ADI
  release: getAppVersion(),
})

let biLogger
let panelDefinitions: any = {}
let isInitialized = false

const fetchSiteId = fetcher()
export const getSiteId = () => fetchSiteId.getData
export const getBiLogger = () => biLogger
export const getPanelDefinitions = () => panelDefinitions
export const setBiLogger = logger => {
  biLogger = logger
}
export const setPanelDefinitions = definitions => {
  panelDefinitions = definitions
}

export const onEvent = Raven.wrap(
  ({ eventType, eventPayload }: { eventType: EDITOR_EVENTS; eventPayload: any }) => {
    switch (eventType) {
      case EDITOR_EVENTS.APP_WIDGET_GFPP_CLICKED:
        handleAppWidgetGfppClicked(eventPayload)
        break
      case EDITOR_EVENTS.GFPP_CLICKED:
        handleGfppClicked(eventPayload)
        break
      case EDITOR_EVENTS.COMPONENT_DELETED:
        handleComponentDelete(eventPayload)
        break
      case EDITOR_EVENTS.SITE_PUBLISHED:
        handlePublish()
        break
      case EDITOR_EVENTS.SITE_FIRST_SAVE:
        handleFirstSave(eventPayload || {})
        break
      case EDITOR_EVENTS.GLOBAL_DESIGN_PRESET_CHANGED:
        handlePresetChanged(eventPayload)
        break
      case EDITOR_EVENTS.WIDGET_PASTED:
        handleAppWidgetPasted(eventPayload)
        break
      default:
        break
    }
  }
)

export const editorReady = Raven.wrap(
  async (editorSDK, appDefinitionId, payload) => {
    const { firstInstall, origin } = payload
    // TODO: Do we need all of this in ADI? use origin.initiator === 'ADI' to skip stuff we don't need
    // TODO: Check where editorSDK is being called without pm-rpc (check console, have an error)
    const msid = await editorSDK.info.getMetaSiteId(appDefinitionId)
    const siteId = await editorSDK.info.getSiteId(appDefinitionId)
    fetchSiteId.resolveData(siteId)
    const withPreset = _.get(origin, 'info.preset')

    Raven.setUserContext({ id: `msid_${msid}` })
    if (!isInitialized && (firstInstall || !origin)) {
      await initApp({ appDefinitionId, editorSDK, msid, origin })
      isInitialized = true
    }
    return startApp(withPreset, origin)
  }
)

export const getAppManifest = Raven.wrap(coreGetAppManifest)

export const apiExports = generateExportedApi()
